* {
  box-sizing: border-box;
}

body {
  margin: 0;
  overscroll-behavior-y: none;
}

a {
  text-decoration: none;
  color: inherit;
}
